<template>
  <section class="sizes" :class="{ isRotatorActive: isRotatorActive }">
    <div class="inner">
      <div class="sizes_image_wrapper">
        <img src="/images/hg_530/HG530_00001.png" alt="" ref="image" class="image_placeholder" />
        <span class="line_vertical line_1 sizes_hide_element1">
          <span class="line_vertical_slider line_1_2"> 82 cm<br />31.5 in </span>
        </span>
        <span class="line_horizontal line_2 sizes_hide_element1">
          <span class="line_horizontal_slider line_2_2">43 cm<br />16.9 in</span>
        </span>
      </div>
      <div class="sizes_image_wrapper">
        <img :src="currentImage" alt="" class="image_size sizes_hide_element2" />
        <span class="line_vertical var_2 line_1 sizes_hide_element2"
          ><span class="line_vertical_slider line_1_2">46 cm<br />18.1 in</span></span
        >
        <span class="line_horizontal var_2 line_2 sizes_hide_element2"
          ><span class="line_horizontal_slider line_2_2">43 cm<br />16.9 in</span></span
        >
      </div>
      <div class="sizes_text_wrapper">
        <h2 class="sizes_hide_element3 sizer_paragraph_line">Details<br />& Downloads.</h2>
        <a href="/images/webarmodel.usdz" rel="ar" class="sizes_hide_element3 ar_link sizer_paragraph_line">
          <img src="../assets/ar.png" alt="" />
          Stuhl in AR anschauen<br />und virtuell platzieren.</a
        >
        <p class="sizes_hide_element3 sizer_paragraph_line">
          Hier finden Sie passende Downloadsfür<br />Ihre Planungssoftware:
        </p>
        <p class="sizes_hide_element3 sizer_paragraph_line">
          <a href="#">3D-Files (OBJ)</a><br />
          <a href="#">Plaungsdaten (.ai)</a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import variables from "../helpers/variables";

import { mapActions, mapState } from "pinia";
import { useModulesStore } from "../stores/modulesStore";
import { useAnimationStore } from "../stores/animationStore";
export default {
  props: {
    type: String,
  },
  data() {
    return {
      framePosition: 75,
      ...variables,
      images: {
        hg_203: "/images/hg_203/HG203_00015.png",
        hg_330: "/images/hg_330/HG330_00015.png",
        hg_520: "/images/hg_520/HG520_00015.png",
        hg_530: "/images/hg_530/HG530_00015.png",
      },
    };
  },
  computed: {
    currentImage() {
      // return this.type ? this.images[this.type] : this.images["hg_330"];
      return this.images["hg_330"];
    },
    ...mapState(useAnimationStore, ["isRotatorActive"]),
  },
  mounted() {
    this.gsapInit();
    this.defineLinesInTimeline();
  },
  methods: {
    ...mapActions(useModulesStore, { addModule: "add", removeModule: "remove" }),
    ...mapActions(useAnimationStore, { addAnimationReference: "setReference" }),
    gsapInit() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top bottom-=300px",
        // endTrigger: "#otherID",
        end: "bottom bottom-=200",
        snap: {
          snapTo: () => {
            return 0.58;
          },
          duration: 0.3,
          delay: 0.1,
          ease: "power1.inOut",
          inertia: false,
          onComplete: () => {
            gsap.set(".image_wrapper", { position: "absolute", top: window.scrollY });
            this.firstLinesInAnimation();
          },
        },
        onToggle: (self) => {
          if (self.isActive) {
            this.addModule(this.$el);
            const data = this.$refs.image.getBoundingClientRect();

            const reference = {
              width: data.width,
              height: data.height,
              left: data.left,
              right: data.right,
              bottom: data.bottom,
              top: data.top,
              x: data.x,
              y: 150,
              frame: this.framePosition,
              direction: self.direction,
            };
            this.addAnimationReference(reference);
            this.$emit("switch-model", "hg_330");
          } else {
            this.removeModule(this.$el);
          }

          // console.log("SIZES: toggled, isActive:", self.isActive);
        },
        onUpdate: (self) => {
          if (self.direction === -1 && self.progress.toFixed(2) < 0.58) {
            if (this.imageIsSticked) {
              gsap.set(".image_wrapper", { clearProps: "position,top" });
              this.firstLinesOutAnimation();
              this.imageIsSticked = false;
            }
          }

          if (self.direction === 1 && self.progress.toFixed(2) >= 0.58) {
            if (!this.imageIsSticked) {
              gsap.set(".image_wrapper", { position: "absolute", top: window.scrollY });
              this.firstLinesInAnimation();
              this.imageIsSticked = true;
            }
          }
        },
      });
    },
    defineLinesInTimeline() {
      this.timeline = gsap.timeline({ paused: true });

      this.timeline.to(".line_1", { duration: 0.7, scaleY: 1, transformOrigin: "center bottom", stagger: 0.3 });
      this.timeline.to(".line_2", { duration: 0.7, scaleX: 1, transformOrigin: "right bottom", stagger: 0.3 }, "-=0.7");
      this.timeline.to(".line_1_2", { top: "0%", opacity: 1, duration: 0.7 }, "-=0.5");
      this.timeline.to(".line_2_2", { left: "0%", opacity: 1, duration: 0.7 }, "-=0.7");
      this.timeline.from(".sizer_paragraph_line", { opacity: 0, y: 10, duration: 0.3, stagger: 0.1 }, "-=0.7");
    },
    firstLinesInAnimation() {
      this.timeline.play();
    },
    firstLinesOutAnimation() {
      this.timeline.reverse();
    },
  },
  watch: {
    isRotatorActive(newValue) {
      if (newValue) {
        gsap.timeline().to(".sizes_hide_element1", { autoAlpha: 0, stagger: 0.02, ease: "power2.inOut" });
        gsap.timeline().to(".sizes_hide_element2", { autoAlpha: 0, stagger: 0.02, ease: "power2.inOut" });
        gsap.timeline().to(".sizes_hide_element3", { autoAlpha: 0, stagger: 0.02, ease: "power2.inOut" });
      } else {
        gsap.timeline().to(".sizes_hide_element1", { autoAlpha: 1, stagger: 0.02, ease: "power2.inOut" });
        gsap.timeline().to(".sizes_hide_element2", { autoAlpha: 1, stagger: 0.02, ease: "power2.inOut" });
        gsap.timeline().to(".sizes_hide_element3", { autoAlpha: 1, stagger: 0.02, ease: "power2.inOut" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sizes {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 170px 0;
  margin-top: -100px;
  transition: background-color 0.3s;

  .sizer_paragraph_line {
    display: inline-block;
    margin-bottom: 0;
  }

  .ar_link {
    position: relative;

    img {
      width: 26px;
      height: 30px;

      position: absolute;
      top: -4px;
      left: 0;
    }
  }

  a.sizer_paragraph_line,
  p.sizer_paragraph_line {
    padding-left: 38px;
    font-family: Helvetica Neue LT Std;
    font-size: 12px;
    text-align: left;
  }

  .line_1 {
    transform: scale(1, 0);
  }

  .line_2 {
    transform: scale(0, 1);
  }

  .line_1_2,
  .line_2_2 {
    opacity: 0;
  }

  .line_1_2 {
    top: 90%;
  }

  .line_2_2 {
    left: 90%;
  }

  .line_horizontal_slider,
  .line_vertical_slider {
    font-family: Helvetica Neue LT Std;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
  }

  .line_vertical_slider {
    position: absolute;
    padding-left: 16px;
    top: 0;
    left: 0;
    display: block;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: #000;
    }
  }

  .line_horizontal_slider {
    position: absolute;
    padding-top: 10px;
    top: 0;
    left: 0;
    display: inline-block;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: #000;
    }
  }

  .line_vertical {
    position: absolute;
    top: 19.1%;
    right: 28%;
    width: 1px;
    height: 65.2%;
    background: #000;

    &.var_2 {
      height: 37%;
      top: 46%;
      right: 25%;
    }
  }

  .line_horizontal {
    position: absolute;
    bottom: calc(16% - 40px);
    left: 21%;
    width: 40%;
    height: 1px;
    background: #000;

    &.var_2 {
      bottom: calc(16% - 40px);
      left: 17%;
      width: 48%;
    }
  }

  .inner {
    margin: 0 auto;
    width: v-bind(containerWidth);
    max-width: v-bind(containerMaxWidth);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_image_wrapper {
    display: block;
    flex: 0 0 35%;
    min-width: 35%;
    position: relative;
  }

  &_text_wrapper {
    flex: 0 0 30%;
    min-width: 30%;
    position: relative;
    z-index: 3;
    padding-left: 30px;

    a {
      font-family: Helvetica Neue LT Std;
      font-size: 12px;
      font-weight: 900;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #000;
      text-decoration: underline;
    }
  }

  h2 {
    font-family: Helvetica Neue LT Std;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 32px 0 !important;
  }

  img.image_size,
  img.image_placeholder {
    width: 820px;
    max-width: 820px;
    margin: 40px 0 -40px -250px;
  }
  img.image_placeholder {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
