<template>
  <section class="paragraph">
    <div class="inner" ref="inner">
      <h2 class="paragraph_hide_element">Gereifte Urform.</h2>
      <p class="paragraph_hide_element">
        Gereifte Urform. icon ist die moderne Interpretation des seit 1918 hergestellten classic, eine Schweizer Ikone
        des traditionellen Holzstuhls. icon transportiert die klassische Ästhetik des Wirtshausstuhls in das 21.
        Jahrhundert. Seine gereifte Urform ist die zeitgenössische Version für moderne Bauten aus Glas, Stahl und Beton.
        Mit seiner Perfektion und seiner Spannung zwischen Tradition und Moderne präsentiert icon Swissness pur.
      </p>
      <img
        src="/images/hg_530/HG530_00007.png"
        alt=""
        ref="image"
        id="paragrahg_image_paceholder"
        class="image_placeholder"
      />

      <a href="" class="paragraph_hide_element blured_image">
        <img src="../assets/classic.png" alt="" />
        <span>Der Urahn des icon <strong>classic 1-380</strong></span>
      </a>
    </div>
  </section>
</template>

<script>
import variables from "../helpers/variables";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { mapActions, mapState } from "pinia";
import { useModulesStore } from "../stores/modulesStore";
import { useAnimationStore } from "../stores/animationStore";

export default {
  data() {
    return {
      framePosition: 15,
      ...variables,
    };
  },
  computed: {
    ...mapState(useAnimationStore, ["isRotatorActive"]),
  },
  mounted() {
    this.gsapInit();
  },
  methods: {
    ...mapActions(useModulesStore, { addModule: "add", removeModule: "remove" }),
    ...mapActions(useAnimationStore, { addAnimationReference: "setReference" }),
    gsapInit() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top center-=200px",
        end: "bottom bottom-=200",
        onToggle: (self) => {
          if (self.isActive) {
            this.addModule(this.$el);
            const data = document.getElementById("paragrahg_image_paceholder").getBoundingClientRect();
            const reference = {
              width: data.width,
              height: data.height,
              left: data.left,
              right: data.right,
              bottom: data.bottom,
              top: data.top,
              x: data.x,
              y: 150,
              frame: this.framePosition,
              direction: self.direction,
            };
            this.$emit("switch-model", "hg_520");
            this.addAnimationReference(reference);
          } else {
            this.removeModule(this.$el);
          }
        },
      });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom-=120",
            end: "bottom center",
            scrub: true,
          },
        })
        .fromTo(".blured_image", { y: -200 }, { y: 500, ease: "power1.inOut" });
    },
  },
  watch: {
    isRotatorActive(newValue) {
      if (newValue) {
        gsap.timeline().to(".paragraph_hide_element", { autoAlpha: 0 });
      } else {
        gsap.timeline().to(".paragraph_hide_element", { autoAlpha: 1 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paragraph {
  position: relative;
  display: block;
  padding-top: 120px;
  overflow: hidden;

  .inner {
    margin: 0 auto;
    width: v-bind(containerWidth);
    max-width: v-bind(containerMaxWidth);
  }

  h2 {
    font-family: Helvetica Neue LT Std;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    margin-left: 45px;
    z-index: 3;
  }

  p {
    font-family: Helvetica Neue LT Std;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    width: 328px;
    margin-left: 85px;
    margin-top: 30px;
    position: relative;
    z-index: 3;
  }

  a {
    position: absolute;
    top: 200px;
    right: 90px;
    z-index: 3;

    // &:hover,
    // &:focus {
    //   img {
    //     filter: blur(0);
    //   }
    // }

    img {
      width: 580px;
      // filter: blur(5px);
      transition: filter 0.3s;
    }

    span {
      display: block;
      position: absolute;
      top: 37%;
      left: 65%;

      font-family: Helvetica Neue LT Std;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #000;
      text-align: left;

      strong {
        font-weight: 900;
        display: block;
        text-decoration: underline;
      }
    }
  }

  img.image_placeholder {
    width: 1300px;
    max-width: 1300px;
    margin: 0 0 0 -300px;
    opacity: 0;
    pointer-events: none;
  }
}
</style>
