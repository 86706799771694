<template>
  <div class="cf_background">
    <div class="msg">
      <span class="row">
        <span ref="text">{{ str }}</span>
        <span ref="shadow">
          <span class="shadow">{{ str }}</span>
        </span>
      </span>
      <span class="row">
        <span ref="text">{{ str }}</span>
        <span class="shadow">{{ str }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfiguratorBackground",
  props: {
    str: String,
  },
  data() {
    return {
      rotation: "81deg",
      translateY: "-108%",
      translateX: 0,
      mouse: {
        current: 0,
      },
      window: {
        percent: 0,
      },
      textTranslate: 0,
      textTranslateBefore: 0,
    };
  },
  created() {
    // window.addEventListener("mousemove", this.onMouseMove);
    // this.interval = setInterval(this.updateMousePosition, 1000 / 60);
  },
  methods: {
    onMouseMove(ev) {
      this.mouse.current = ev.screenX;
      this.window.percent = this.mouse.current / window.innerWidth;
      this.window.percentY = ev.screenY / window.innerHeight;
      this.translateX = `${this.window.percent * 60 - 30}px`;
      this.translateY = `${-108 + this.window.percentY * 20 - 10}%`;
      this.textTranslateBefore = this.window.percent * (this.$refs.text.offsetWidth - window.innerWidth);
    },
    updateMousePosition() {
      this.textTranslate = this.lerp(this.textTranslate, this.textTranslateBefore, 0.1);
    },
    lerp(start, end, amt) {
      return (1 - amt) * start + amt * end;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
    /* changed from 100% */
  }
}

.cf_background {
  white-space: nowrap;
  position: absolute;
  // perspective: 1000px;
  line-height: 1;
  font-family: Helvetica Neue LT Std;
  font-size: 371px;
  font-weight: 900;
  line-height: 371px;
  letter-spacing: 0em;
  text-align: left;
  top: 240px;
  width: 100%;
  overflow: hidden;

  & > span {
    display: inline-block;
    padding: 0 10%;
    color: #000;
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    color: #000;

    transform-origin: center bottom;
    transform: rotateX(v-bind(rotation)) translateY(v-bind(translateY)) translateX(v-bind(translateX));
    filter: blur(50px);
    opacity: 0.2;
  }

  .msg {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 45s linear infinite;
    display: inline-block;

    .row {
      position: relative;
      padding: 0 100px;
    }
  }
}
$duration: 60s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
</style>
