const chairsTypes = ["hg_203", "hg_330", "hg_520", "hg_530"];
const defaultChairType = "hg_530";

const amountOfPhotos = 80;
const chairs = chairsTypes.map((v) => {
  const images = [];
  const imagePrefix = v.replace("_", "").toUpperCase();
  for (let i = 0; i < amountOfPhotos; i++) {
    const number = i.toString().padStart(5, "0");
    const imagePath = `/images/${v}/${imagePrefix}_${number}.png`;

    images.push(imagePath);
  }
  return {
    id: v,
    images,
  };
});

export { chairsTypes, defaultChairType, chairs };
