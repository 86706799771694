<template>
  <section>
    <AppConfigurator />
  </section>
</template>

<script>
import AppConfigurator from "./components/AppConfigurator.vue";

export default {
  name: "App",
  components: {
    AppConfigurator,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("./assets/fonts/HelveticaNeueLTStd-Blk.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueLTStd-Blk.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("./assets/fonts/HelveticaNeueLTStd-Roman.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueLTStd-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body,
html {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
}

#app {
  font-family: "Helvetica Neue LT Std", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  width: 100%;
}

.preload_image {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
