import { defineStore } from "pinia";

const useModulesStore = defineStore("modulesStore", {
  state: () => ({
    modules: [],
  }),
  actions: {
    add(value) {
      if (this.modules.indexOf(value) === -1) {
        this.modules.push(value);
      }
    },
    remove(value) {
      const index = this.modules.indexOf(value);
      if (index !== -1) {
        this.modules.splice(index, 1);
      }
    },
  },
});

export { useModulesStore };
