<template>
  <section class="switcher" :class="{ is_hidden: isScrolled, is_open: isOpen || isRotatorActive }">
    <div class="selector">
      <span class="selector_label" v-show="currenVariantLabel"
        >Variante:
        <span class="selector_value">
          <transition name="fade">
            <strong :key="currenVariantLabel">{{ currenVariantLabel }}</strong>
          </transition>
        </span>
      </span>
      <ul class="variants">
        <li
          v-for="(variant, n) in variants"
          :key="variant.name"
          :class="{ is_active: currenVariantIndex === n }"
          @click="currenVariantIndex = n"
        >
          <img :src="variant.image" style="width: 14px" alt="" />
        </li>
      </ul>
    </div>
    <div class="selector">
      <span class="selector_label" v-show="currenColorLabel"
        >Material:
        <span class="selector_value">
          <transition name="fade">
            <strong :key="currenColorLabel">{{ currenColorLabel }}</strong>
          </transition>
        </span>
      </span>
      <ul class="colors">
        <li
          v-for="(col, n) in colors"
          :key="col.name"
          :class="{ is_active: currenColorIndex === n }"
          @click="currenColorIndex = n"
        >
          <span>
            <img :src="col.image" style="width: 20px" alt="" />
          </span>
        </li>
        <li class="start">
          <span>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.04186 0.25H2.99535V2.70581L0.669767 1.90581L0 3.8593L2.3814 4.62209L0.8 6.57558L2.47442 7.89651L3.96279 5.77558L5.43256 7.89651L7.10698 6.57558L5.6186 4.62209L8 3.8593L7.29302 1.90581L5.04186 2.70581V0.25Z"
                fill="black"
              />
            </svg>
          </span>
        </li>
      </ul>
    </div>
    <div class="selector">
      <a href="/images/icon.usdz" rel="ar">
        <img src="../assets/ar.png" class="web_ar" style="width: 26px" alt="" />
      </a>
    </div>
  </section>
</template>

<script>
import variables from "../helpers/variables";

import { mapState } from "pinia";
import { useAnimationStore } from "../stores/animationStore";

export default {
  props: {
    updateColor: String,
  },
  data() {
    return {
      ...variables,
      isScrolled: false,
      isOpen: false,
      currenVariantIndex: 0,
      currenColorIndex: 1,
      currenVariant: false,
      currenColor: false,
      variants: [
        {
          name: "1-340",
          image: require("../assets/variants/varianten-1.png"),
        },
        {
          name: "1-350",
          image: require("../assets/variants/varianten-2.png"),
        },
      ],
      colors: [
        {
          key: "hg_520",
          name: "Buche natur HG 520",
          image: require("../assets/configurator/01-hg520.png"),
        },
        {
          key: "hg_530",
          name: "Eiche natur HG 530",
          image: require("../assets/configurator/02-hg530.png"),
        },
        {
          key: "hg_330",
          name: "Buche weiss HG 330",
          image: require("../assets/configurator/03-hg330.png"),
        },
        {
          key: false,
          name: "Buche hellgrau HG 340",
          image: require("../assets/configurator/04-hg340.png"),
        },
        {
          key: false,
          name: "Buche grau HG 350",
          image: require("../assets/configurator/05-hg350.png"),
        },
        {
          key: "hg_203",
          name: "Buche schwarz HG 203",
          image: require("../assets/configurator/06-hg203.png"),
        },
        {
          key: false,
          name: "Buche anthrazit HG 200",
          image: require("../assets/configurator/07-hg200.png"),
        },
        {
          key: false,
          name: "Esche / Eiche schwarz HG 580",
          image: require("../assets/configurator/08-hg580.png"),
        },
        {
          key: false,
          name: "Eiche gebeizt Räuchereiche  HG 535",
          image: require("../assets/configurator/09-hg535.png"),
        },
        {
          key: false,
          name: "Buche Ebony HG 100",
          image: require("../assets/configurator/10-hg100.png"),
        },
        {
          key: false,
          name: "Buche Wenge HG 110",
          image: require("../assets/configurator/11-hg110.png"),
        },
        {
          key: false,
          name: "Buche Mahagoni HG 130",
          image: require("../assets/configurator/12-hg130.png"),
        },
        {
          key: false,
          name: "Buche Maron HG 120",
          image: require("../assets/configurator/13-hg120.png"),
        },
        {
          key: false,
          name: "Buche Nuss HG 151",
          image: require("../assets/configurator/14-hg151.png"),
        },
        {
          key: false,
          name: "Buche geweisst HG 172",
          image: require("../assets/configurator/15-hg172.png"),
        },
      ],
    };
  },
  computed: {
    ...mapState(useAnimationStore, ["isRotatorActive"]),
    currenVariantLabel() {
      if (this.currenVariantIndex === null) return false;
      return this.variants[this.currenVariantIndex].name;
    },
    currenColorLabel() {
      if (this.currenColorIndex === null) return false;
      return this.colors[this.currenColorIndex].name;
    },
  },
  mounted() {
    this.currenVariant = this.variants[0].name;
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      if (window.scrollY > 10) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
  watch: {
    currenColorIndex: function (newValue) {
      this.$emit("switcher-change", this.colors[newValue]);
    },
    updateColor(newValue) {
      const newIndex = this.colors.findIndex((v) => v.key === newValue);
      this.currenColorIndex = newIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.switcher {
  position: fixed;
  bottom: 100px;
  left: calc(50vw - 445px);
  display: flex;
  z-index: 4;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, opacity 0.3s;

  .web_ar,
  .svg {
    transition: opacity 0.3s;
  }

  &.is_hidden {
    // opacity: 0;
    transform: translate(0, 10px);

    ul li,
    .selector_label,
    .web_ar,
    .svg {
      opacity: 0;
    }
  }

  &.is_hidden.is_open {
    // opacity: 0;
    transform: translate(0, 0px);

    ul li,
    .web_ar,
    .selector_label,
    .svg {
      opacity: 1;
    }
  }

  &.is_open {
    bottom: 20px;
  }
}

.selector {
  position: relative;

  &_label {
    position: absolute;
    top: -13px;
    left: 0;
    transform: translate(0, -100%);
    font-size: 12px;
    transition: opacity 0.3s;
    strong {
      font-weight: 900;
      display: inline-block;
    }
  }

  &_value {
    position: relative;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      cursor: pointer;
      transition: opacity 0.3s;
      $count: 0;

      @while $count < 20 {
        &:nth-child(#{$count}) {
          transition-delay: 0.02s * $count;
        }

        $count: $count + 1;
      }

      &.is_active {
        &:after {
          transform: scale(1, 1);
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #000;
        transform: scale(0, 1);
        transition: transform 0.3s;
      }
    }

    li.is_active {
    }
  }

  ul.variants {
    margin-right: 80px;
    li:not(:first-child) {
      margin-left: 25px;
    }
  }

  ul.colors {
    margin-right: 60px;
    li {
      margin-right: 20px;

      &.is_active {
        span {
          img {
            transform: scale(1.5);
          }
        }
      }

      &.start {
        span {
          border: 1px solid #000;
          border-radius: 50%;

          svg {
            display: block;
            margin: 5px auto;
          }
        }
      }

      span {
        display: block;
        width: 20px;
        height: 20px;
        overflow: hidden;

        img {
          border-radius: 50%;
          transition: transform 0.3s;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s;
}

.fade-enter-active {
  position: absolute;
  white-space: nowrap;
  left: 0;
}

.fade-leave-to {
  transform: translate(0, -100%);
  opacity: 0;
}

.fade-enter-from {
  transform: translate(0, 100%);
  opacity: 0;
}
</style>
