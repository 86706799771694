<template>
  <div class="configurator">
    <app-header ref="header"></app-header>
    <configurator-background :str="productName"></configurator-background>
    <image-rotator :type="type"></image-rotator>
    <span class="configurator_rotator_placeholder"></span>
    <configurator-switcher
      ref="switcher"
      :updateColor="type"
      @switcher-change="onSwitcherChange"
    ></configurator-switcher>
    <app-gallery ref="gallery"></app-gallery>
    <app-paragraph @switch-model="setModel"></app-paragraph>
    <app-the-designers @switch-model="setModel"></app-the-designers>
    <app-sizes :type="type" @switch-model="setModel"></app-sizes>
    <app-footer ref="footer"></app-footer>
  </div>
</template>

<script>
import ConfiguratorBackground from "./ConfiguratorBackground.vue";
import ConfiguratorSwitcher from "./ConfiguratorSwitcher.vue";
import ImageRotator from "./ImageRotator.vue";
import AppHeader from "./AppHeader.vue";
import AppFooter from "./AppFooter.vue";
import AppGallery from "./AppGallery.vue";
import AppParagraph from "./AppParagraph.vue";
import AppTheDesigners from "./AppTheDesigners.vue";
import AppSizes from "./AppSizes.vue";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { mapState } from "pinia";
import { mapActions } from "pinia";
import { useModulesStore } from "../stores/modulesStore";

export default {
  name: "AppConfigurator",
  components: {
    AppFooter,
    AppGallery,
    AppHeader,
    AppTheDesigners,
    AppSizes,
    AppParagraph,
    ImageRotator,
    ConfiguratorBackground,
    ConfiguratorSwitcher,
  },
  data() {
    return {
      productName: "icon 1-340",
      currentChair: null,
      type: "hg_530",
    };
  },
  mounted() {},
  computed: {
    // type() {
    //   return this.currentChair?.key || "hg_530";
    // },

    ...mapState(useModulesStore, ["modules"]),
  },
  methods: {
    ...mapActions(useModulesStore, { addModule: "add" }),
    setModel(model) {
      this.type = model;
    },
    onSwitcherChange(newValue) {
      this.currentChair = newValue;
      const type = this.currentChair?.key || "hg_530";
      this.setModel(type);
    },
    gsapInit() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top top",
        // endTrigger: "#otherID",
        end: "bottom bottom",
        onUpdate: () => {
          if (window.scrollY > 10) {
            gsap.to(".switcher", {
              opacity: 0,
              y: 100,
              onComplete: () => {
                gsap.set(".switcher", { bottom: 0 });
              },
            });
            gsap.to(".header_link", { opacity: 0, y: -20, stagger: 0.05 });
          } else {
            gsap.set(".switcher", { bottom: "100px" });
            gsap.to(".switcher", { opacity: 1, y: 0 });
            gsap.to(".header_link", { opacity: 1, x: 0, stagger: 0.05 });
          }
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.configurator {
  &_rotator_placeholder {
    display: block;
    width: 1200px;
    max-width: 100vw;

    &:after {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 50%;
    }
    margin: 0 auto;
  }
}
</style>
