<template>
  <section class="gallery">
    <div class="inner">
      <img class="gallery_image gallery_image_popup" src="../assets/image1.png" style="width: 613px" alt="" />
      <img class="gallery_image gallery_image_popup" src="../assets/image2.png" style="width: 423px" alt="" />
      <img
        class="gallery_image"
        ref="popupImage"
        v-touch="showPopup"
        src="../assets/image3.png"
        style="width: 329px"
        alt=""
      />
      <img class="gallery_image gallery_image_popup" src="../assets/image4.png" style="width: 472px" alt="" />
      <img class="gallery_image gallery_image_popup" src="../assets/image5.png" style="width: 423px" alt="" />
      <img class="gallery_image gallery_image_popup" src="../assets/image6.png" style="width: 329px" alt="" />
      <span class="clear"></span>
    </div>
    <div class="gallery_popup" :class="{ is_active: isPopupActive }">
      <div class="inner">
        <span class="gallery_popup_in gallery_popup_close" v-touch="setPopupUnActive">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="black" />
            <path d="M13 13L27 27" stroke="white" />
            <path d="M27 13L13 27" stroke="white" />
          </svg>
        </span>

        <h2 class="gallery_popup_in">gloria t-1002</h2>
        <p class="gallery_popup_in label">Werkentwurf, 1925</p>
        <img class="gallery_popup_in" src="../assets/button.png" style="width: 182px" alt="" />
        <img src="../assets/table.png" class="table gallery_popup_table" style="width: 685px" alt="" />
        <img src="../assets/switcher.png" class="gallery_popup_switch" style="width: 620px" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import variables from "../helpers/variables";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

import { mapActions, mapState } from "pinia";
import { useModulesStore } from "../stores/modulesStore";
import { useAnimationStore } from "../stores/animationStore";
import { throttle } from "throttle-debounce";

export default {
  data() {
    return {
      ...variables,
    };
  },
  computed: {
    ...mapState(useAnimationStore, ["isRotatorActive", "isPopupActive"]),
  },
  created() {
    this.trottledFunction = throttle(300, this.onScroll);
  },
  mounted() {
    this.gsapInit();
  },
  methods: {
    ...mapActions(useModulesStore, { addModule: "add", removeModule: "remove" }),
    ...mapActions(useAnimationStore, {
      addAnimationReference: "setReference",
      setPopupActive: "setPopupActive",
      setPopupUnActive: "setPopupUnActive",
    }),
    onScroll() {
      this.setPopupUnActive();
    },
    gsapInit() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top bottom-=300px",
        // endTrigger: "#otherID",
        end: "bottom center-=200",
        onToggle: (self) => {
          if (self.isActive) {
            this.addModule(this.$el);
            const reference = false;
            this.addAnimationReference(reference);
          } else {
            this.removeModule(this.$el);
          }
        },
      });
    },
    toggleGallery(open) {
      const middle = window.innerWidth / 2;
      if (open) {
        gsap.timeline().to(".gallery_image", {
          autoAlpha: 0,
          x: (i, el) => {
            const d = el.getBoundingClientRect();
            if (d.left < middle) {
              return "-50%";
            } else {
              return "50%";
            }
          },
          stagger: 0.05,
          ease: "power2.inOut",
        });
      } else {
        gsap.timeline().to(".gallery_image", { autoAlpha: 1, x: 0 });
      }
    },
    toggleGalleryPopup(open) {
      const middle = window.innerWidth / 2;
      if (open) {
        const imageData = this.$refs.popupImage.getBoundingClientRect();
        const toTop = (window.innerHeight - imageData.height) / 2;
        const imageTop = window.scrollY - toTop + imageData.top;

        gsap.to(window, { duration: 0.3, scrollTo: imageTop });

        gsap
          .timeline({
            onComplete: () => {
              window.addEventListener("scroll", this.trottledFunction, false);
            },
          })
          .to(".gallery_image_popup", {
            autoAlpha: 0,
            x: (i, el) => {
              const d = el.getBoundingClientRect();
              if (d.left < middle) {
                return "-50%";
              } else {
                return "50%";
              }
            },
            stagger: 0.05,
            ease: "power2.inOut",
          })
          .to(this.$refs.popupImage, { x: 20 }, "-=0.5")
          .to(".header_link_svg", { opacity: 0, duration: 0.3 }, "-=0.3")
          .fromTo(
            ".gallery_popup_in",
            {
              autoAlpha: 0,
              y: -150,
            },
            {
              autoAlpha: 1,
              y: 0,
              stagger: -0.1,
              duration: 0.3,
              // ease: "power2.In",
            },
            "-=0.3"
          )
          .fromTo(
            ".gallery_popup_table",
            {
              autoAlpha: 0,
            },
            {
              autoAlpha: 1,
              duration: 0.3,
              ease: "power2.Out",
            },
            "-=0.3"
          )
          .fromTo(".gallery_popup_switch", { autoAlpha: 0, y: 100 }, { autoAlpha: 1, y: 0 }, "-=0.2");
      } else {
        gsap
          .timeline({
            onComplete: () => {
              window.removeEventListener("scroll", this.trottledFunction, false);
            },
          })
          .to(".gallery_popup_table", { autoAlpha: 0, duration: 0.3, ease: "power2.In" })
          .to(".gallery_popup_switch", { autoAlpha: 0, y: 100, duration: 0.3 }, "-=0.3")
          .to(".gallery_popup_in", { autoAlpha: 0, y: -150, stagger: 0.1, ease: "power2.Out" }, "-=0.3")
          .to(".header_link_svg", { opacity: 1, duration: 0.3 }, "-=0.3")
          .to(".gallery_image", { autoAlpha: 1, x: 0 })
          .to(this.$refs.popupImage, { x: 0 }, "-=0.5");
      }
    },
    showPopup() {
      if (!this.isPopupActive) {
        this.setPopupActive();
      } else {
        this.setPopupUnActive();
      }
    },
  },
  watch: {
    isRotatorActive(newValue) {
      this.toggleGallery(newValue);
    },
    isPopupActive(newValue) {
      this.toggleGalleryPopup(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // background: #fff;
  z-index: 2;

  &:not(.is_active) {
    pointer-events: none;
  }

  .gallery_popup_switch {
    position: absolute;
    left: calc(50% - 310px);
    bottom: 20px;
  }

  .gallery_popup_close {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .gallery_popup_in {
    display: block;
  }

  .inner {
    text-align: center;

    & > * {
      opacity: 0;
    }

    h2 {
      font-family: Helvetica Neue LT Std;
      font-size: 40px;
      font-weight: 900;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 44px;
      margin-bottom: 5px;
    }

    .label {
      font-family: Helvetica Neue LT Std;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0 0 20px 0;
    }

    img {
      margin: 0 auto;
      display: block;
      float: none !important;
    }

    img.table {
      position: absolute;
      top: 40%;
      left: 45%;
    }
  }
}
.gallery {
  position: relative;
  display: block;
  margin-top: 100px;
  overflow: hidden;

  .inner {
    margin: 0 auto;
    width: v-bind(containerWidth);
    max-width: v-bind(containerMaxWidth);
  }

  img {
    margin-top: 40px;
    max-width: 50%;
    z-index: 3;
    position: relative;
    // pointer-events: none;

    &:nth-child(2n + 1) {
      float: left;
      clear: left;
    }
    &:nth-child(2n + 2) {
      float: right;
      clear: right;
    }
  }

  .clear {
    clear: both;
    width: 100%;
    display: block;
  }
}
</style>
