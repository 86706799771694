import { defineStore } from "pinia";

const useAnimationStore = defineStore("animationStore", {
  state: () => ({
    reference: false,
    isRotatorActive: false,
    isPopupActive: false,
  }),
  actions: {
    setRotatorActive() {
      this.isRotatorActive = true;
    },
    setRotatorUnActive() {
      this.isRotatorActive = false;
    },
    setPopupActive() {
      this.isPopupActive = true;
    },
    setPopupUnActive() {
      this.isPopupActive = false;
    },
    setReference(obj) {
      this.reference = obj;
    },
    clearReference() {
      this.reference = false;
    },
  },
});

export { useAnimationStore };
