<template>
  <section class="image_wrapper">
    <transition name="fade">
      <span class="image_wrapper_loader" v-show="isLoading">{{ loadPercentage }}% loading...</span>
    </transition>
    <div ref="rotatorContainer" class="rotator_wrapper">
      <div ref="rotator" v-touch="touchHandler" class="rotator"></div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { chairs } from "../helpers/chairsData";
import { mapState } from "pinia";
import { mapActions } from "pinia";
import { useAnimationStore } from "../stores/animationStore";

import ThreeSixty from "@mladenilic/threesixty.js";
import { throttle } from "throttle-debounce";

let threesixty;

export default {
  props: {
    type: String,
  },
  data() {
    return {
      isLoading: true,
      loadPercentage: 0,
      animationInterval: false,
      initScrollTopPosition: 0,
      threesixty: null,
      lastKnownPosition: 0,
      initWidth: 1200,
      width: 1200,
      height: 540,
      imageRotatorData: null,
      loadedTypes: [],
    };
  },
  computed: {
    ...mapState(useAnimationStore, ["reference", "isRotatorActive", "isPopupActive"]),
  },
  created() {
    window.addEventListener("scroll", throttle(300, this.onScroll));
    this.loadImages();
  },
  methods: {
    loadImages() {
      if (this.loadedTypes.indexOf(this.type) > -1) {
        this.destroyRotator();
        this.initRotator();
        if (!this.imageRotatorData) {
          this.imageRotatorData = this.$refs.rotator.getBoundingClientRect();
        }
        return true;
      }

      this.loadPercentage = 0;
      let loaded_images = 0;
      const currentChairModel = chairs.find((v) => v.id === this.type);

      this.isLoading = true;
      this.loadedTypes.push(this.type);

      for (var i = 0; i < currentChairModel.images.length; i++) {
        var img = document.createElement("img");
        img.src = currentChairModel.images[i];
        img.style.width = "1px";
        img.style.opacity = "0";
        img.classList.add("preload_image");
        img.onload = () => {
          loaded_images++;
          if (loaded_images == currentChairModel.images.length) {
            if (threesixty) {
              this.destroyRotator();
            }
            this.initRotator();

            if (!this.imageRotatorData) {
              this.imageRotatorData = this.$refs.rotator.getBoundingClientRect();
            }

            this.isLoading = false;
          } else {
            this.loadPercentage = Math.round((100 * loaded_images) / currentChairModel.images.length);
          }
        };
        document.body.appendChild(img);
      }
    },
    ...mapActions(useAnimationStore, ["setRotatorActive", "setRotatorUnActive"]),
    onScroll() {
      if (Math.abs(this.initScrollTopPosition - window.scrollY) > 10) {
        this.setRotatorUnActive();
        this.initScrollTopPosition = 0;
      }
    },
    touchHandler() {
      this.setRotatorActive();
      this.initScrollTopPosition = window.scrollY;
    },
    rotatorClicked() {},
    initRotator() {
      const currentModel = chairs.find((v) => v.id === this.type);
      const width = Math.min(this.initWidth, window.innerWidth);
      const height = width * 0.6;

      this.width = width;
      this.height = height;

      threesixty = new ThreeSixty(this.$refs.rotator, {
        image: currentModel.images,
        width,
        height,
        speed: 100,
      });

      threesixty.goto(this.lastKnownPosition);
    },
    destroyRotator() {
      this.lastKnownPosition = threesixty.index;
      threesixty.destroy();
    },
    loadNewModel() {
      this.loadImages();
    },
    calculateClosestDistance(start, end) {
      const exampleModel = chairs.find((v) => v.id === this.type);
      const amountOfImages = exampleModel.images.length;
      let result = false;

      const isForward = start < end;
      if (isForward) {
        result = end - start <= amountOfImages - end + start;
      } else {
        result = amountOfImages - start + end <= start - end;
      }

      return !result;
    },
    goToPosition(index) {
      this.desPosition = index;
      const exampleModel = chairs.find((v) => v.id === this.type);
      const amountOfImages = exampleModel.images.length;

      this.reverse = this.calculateClosestDistance(threesixty.index, index);
      let distance = 0;
      if (!this.reverse) {
        distance = threesixty.index >= index ? amountOfImages - threesixty.index + index : index - threesixty.index;
      } else {
        distance = threesixty.index >= index ? threesixty.index - index : amountOfImages - index + threesixty.index;
      }

      const time = 500 / distance;

      if (!this.animationInterval) {
        this.animationInterval = setInterval(this.goToPositionAnimation, time);
      }
    },
    goToPositionAnimation() {
      if (threesixty.index !== this.desPosition) {
        if (this.reverse) {
          threesixty.prev();
        } else {
          threesixty.next();
        }
      } else {
        clearInterval(this.animationInterval);
        this.animationInterval = false;
      }
    },
    updatePositionAndSize() {
      if (this.reference) {
        const scale = this.reference.width / this.imageRotatorData.width;
        const x = this.reference.left - this.imageRotatorData.left;
        let animationObject = {};

        if (Math.round(this.reference.width / 10) !== Math.round(this.imageRotatorData.width / 10)) {
          animationObject = { ...animationObject, scale };
        }

        if (Math.round(this.reference.left / 10) !== Math.round(this.imageRotatorData.left / 10)) {
          animationObject = { ...animationObject, x };
        }

        if (this.reference?.y) {
          animationObject = { ...animationObject, y: this.reference.y };
        }

        gsap.to(this.$refs.rotatorContainer, animationObject);
        this.goToPosition(this.reference.frame);
      } else {
        this.goToPosition(1);
        gsap.to(this.$refs.rotatorContainer, { x: 0, y: 0, scale: 1 });
      }
    },
  },
  watch: {
    reference() {
      this.updatePositionAndSize();
    },
    isRotatorActive(newValue) {
      if (newValue) {
        gsap.to(this.$refs.rotatorContainer, { x: 0, y: 0, scale: 1 });
      } else {
        this.updatePositionAndSize();
      }
    },
    isPopupActive(newValue) {
      if (newValue) {
        gsap.to(this.$refs.rotatorContainer, { autoAlpha: 0 });
      } else {
        gsap.to(this.$refs.rotatorContainer, { autoAlpha: 1, delay: 0.6 });
      }
    },
    type() {
      this.loadNewModel();
    },
  },
};
</script>

<style scoped lang="scss">
.rotator {
  transform-origin: 0 50%;
  background-size: min(100vw, 1200px);
  background-repeat: no-repeat;
}

.rotator_wrapper {
  transform-origin: 0 50%;
}

.image_wrapper_loader {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image_wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  justify-content: center;
  align-items: center;
  // pointer-events: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s;
}

.fade-leave-to {
  opacity: 0;
}

.fade-enter-from {
  opacity: 0;
}
</style>
