<template>
  <section class="designers" :class="{ isRotatorActive: isRotatorActive }">
    <div class="designers_wrapper">
      <div class="inner">
        <h2 class="designers_hide_element1">die Designer.</h2>
        <img src="/images/hg_530/HG530_00011.png" alt="" ref="image" class="image_placeholder" />
        <div class="person var_1">
          <img
            class="designers_hide_element2 designers_person_image"
            src="../assets/person1.png"
            alt=""
            style="width: 150px"
          />
          <p class="designers_hide_element2"><strong>Stephan Hürlemann</strong></p>
          <p class="designers_hide_element2">
            Schweizer Architekt und Designer. Studierte Architektur an der ETH Zürich. Ab 2002 Geschäftsführer der
            Agentur von Hannes Wettstein (1958–2008). Etablierte sich nach Wettsteins Tod zum kreativen Kopf der
            Agentur, die bis Anfang 2016 unter dem Namen Studio Hannes Wettstein agierte und heute Hürlemann heisst.
          </p>
        </div>
        <div class="person var_2">
          <img
            class="designers_hide_element3 designers_person_image"
            src="../assets/person2.png"
            alt=""
            style="width: 150px"
          />
          <p class="designers_hide_element3"><strong>Simon Husslein</strong></p>
          <p class="designers_hide_element3">
            Industrial- und Produktdesigner. Studium in Darmstadt und London. 2000–2005 Mitarbeit bei Hannes Wettstein
            in Zürich. 2007–2008 eigene Projekte in London und Shanghai sowie Lehrtätigkeit an der Tongji Universität
            Shanghai. 2008–2014 Mitglied der Geschäftsleitung und Gestalter bei Studio Hannes Wettstein.
          </p>
        </div>
      </div>
    </div>
    <span class="background" id="designers_background"></span>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import variables from "../helpers/variables";

import { mapActions, mapState } from "pinia";
import { useModulesStore } from "../stores/modulesStore";
import { useAnimationStore } from "../stores/animationStore";
export default {
  data() {
    return {
      framePosition: 23,
      ...variables,
    };
  },
  computed: {
    ...mapState(useAnimationStore, ["isRotatorActive"]),
  },
  mounted() {
    this.gsapInit();
  },
  methods: {
    ...mapActions(useModulesStore, { addModule: "add", removeModule: "remove" }),
    ...mapActions(useAnimationStore, { addAnimationReference: "setReference" }),
    gsapInit() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top bottom-=300px",
        // endTrigger: "#otherID",
        end: "bottom bottom-=200",
        onToggle: (self) => {
          if (self.isActive) {
            this.addModule(this.$el);
            const data = this.$refs.image.getBoundingClientRect();
            // const innerData = this.$refs.inner.getBoundingClientRect();
            const reference = {
              width: data.width,
              height: data.height,
              left: data.left,
              right: data.right,
              bottom: data.bottom,
              top: data.top,
              x: data.x,
              y: 150,
              frame: this.framePosition,
              direction: self.direction,
            };
            this.$emit("switch-model", "hg_203");
            this.addAnimationReference(reference);
          } else {
            this.removeModule(this.$el);
          }

          // if (self.isActive && self.direction === 1) {
          // gsap.set("#designers_background", { scaleY: 1 });
          // gsap.fromTo(
          //   "#designers_background",
          //   { yPercent: -200, opacity: 1 },
          //   {
          //     yPercent: 0,
          //     duration: 2,
          //     opacity: 1,
          //   }
          // );

          // gsap.fromTo(
          //   ".designers_person_image",
          //   { yPercent: 30, opacity: 0 },
          //   { yPercent: 0, opacity: 1, duration: 1, stagger: 0.4, delay: 0.3 }
          // );
          // }

          // if (!self.isActive && self.direction === -1) {
          //   gsap
          //     .timeline()
          //     // .to("#designers_background", { yPercent: -200, opacity: 1, duration: 0.7 })
          //     .to("#designers_background", { scaleY: 0, transformOrigin: "center top", duration: 0.7 }, "-=0.5");

          //   gsap.to(".designers_person_image", { yPercent: 30, opacity: 0 });
          // }

          console.log("DESIGNERS: toggled, isActive:", self.isActive);
        },
      });
    },
  },
  watch: {
    isRotatorActive(newValue) {
      if (newValue) {
        gsap.timeline().to(".designers_hide_element1", { autoAlpha: 0, ease: "power2.inOut" });
        gsap.timeline().to(".designers_hide_element2", { autoAlpha: 0, stagger: 0.05, ease: "power2.inOut" });
        gsap.timeline().to(".designers_hide_element3", { autoAlpha: 0, stagger: 0.05, ease: "power2.inOut" });
      } else {
        gsap.timeline().to(".designers_hide_element1", { autoAlpha: 1, ease: "power2.inOut" });
        gsap.timeline().to(".designers_hide_element2", { autoAlpha: 1, stagger: 0.05, ease: "power2.inOut" });
        gsap.timeline().to(".designers_hide_element3", { autoAlpha: 1, stagger: 0.05, ease: "power2.inOut" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.designers {
  position: relative;
  display: block;
  z-index: 1;
  color: #fff;
  padding: 80px 0;
  margin-top: -100px;

  &_wrapper {
    overflow: hidden;
    display: block;
    width: 100%;
  }

  .designers_person_image {
    // opacity: 0;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: background-color 0.3s;
    z-index: -1;
    // opacity: 0;
  }

  &.isRotatorActive {
    .background {
      background: #fff;
    }
  }

  .inner {
    margin: 0 auto;
    width: v-bind(containerWidth);
    max-width: v-bind(containerMaxWidth);
  }

  h2 {
    font-family: Helvetica Neue LT Std;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    margin-left: 45px;
  }

  p {
    font-family: Helvetica Neue LT Std;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    letter-spacing: 0em;
    text-align: left;
    width: 340px;
    margin-left: 190px;

    strong {
      font-weight: 900;
    }
  }

  .person {
    position: absolute;
    right: 163px;
    top: 169px;

    &.var_2 {
      top: 447px;
      right: 113px;
    }
    img {
      float: left;
    }
  }

  img.image_placeholder {
    width: 900px;
    max-width: 900px;
    margin: 40px 0 -40px -250px;
    opacity: 0;
    pointer-events: none;
  }
}
</style>
